import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc, query, where, onSnapshot } from 'firebase/firestore';
import './Orders.css';
import OrderDashboard from '../OrderDashboard/OrderDashboard';
import { Autocomplete, TextField } from '@mui/material';

const Orders = () => {
  const [jobType, setJobType] = useState('');
  const [quantity, setQuantity] = useState('');
  const [deadline, setDeadline] = useState('');
  const [materials, setMaterials] = useState('');
  const [amount, setAmount] = useState('');
  const [productDetails, setProductDetails] = useState('');
  const [gstDetails, setGstDetails] = useState('');
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editingOrder, setEditingOrder] = useState(null);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    const unsubscribe = onSnapshot(
      query(collection(db, 'orders'), where('userId', '==', user.uid)),
      (snapshot) => {
        const ordersList = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setOrders(ordersList);
      }
    );

    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productsList);
    };

    fetchProducts();
    return () => unsubscribe();
  }, [user, navigate]);

  const handleProductChange = (event, value) => {
    if (value) {
      setJobType(value.name);
      setProductDetails(value.details);
      setGstDetails(value.gst);
      setMaterials(value.materials); // Assuming product materials are also in the product details
    } else {
      setJobType('');
      setProductDetails('');
      setGstDetails('');
      setMaterials('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editingOrder) {
      await handleEditSubmit();
    } else {
      await handleNewOrderSubmit();
    }
  };

  const calculateGstAmount = (amount, gstRate) => {
    return (amount / 100) * gstRate;
  };

  const handleNewOrderSubmit = async () => {
    try {
      const gstAmount = calculateGstAmount(amount, gstDetails);

      const orderRef = await addDoc(collection(db, 'orders'), {
        jobType,
        quantity,
        deadline,
        materials,
        amount,
        productDetails,
        gstDetails,
        gstAmount, // Store GST amount
        userId: user.uid,
        completed: false
      });

      await addDoc(collection(db, 'finance'), {
        orderId: orderRef.id,
        jobType,
        amount,
        gstAmount, // Store GST amount
        userId: user.uid
      });

      setShowForm(false);
      resetForm();
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  };

  const handleEditSubmit = async () => {
    const orderRef = doc(db, 'orders', editingOrder.id);
    try {
      const gstAmount = calculateGstAmount(amount, gstDetails);

      await updateDoc(orderRef, {
        jobType,
        quantity,
        deadline,
        materials,
        amount,
        productDetails,
        gstDetails,
        gstAmount // Update GST amount
      });

      const financeQuerySnapshot = await getDocs(query(collection(db, 'finance'), where('orderId', '==', editingOrder.id)));
      if (!financeQuerySnapshot.empty) {
        const financeDoc = financeQuerySnapshot.docs[0];
        const financeRef = doc(db, 'finance', financeDoc.id);
        await updateDoc(financeRef, {
          jobType,
          amount,
          gstAmount // Update GST amount
        });
      }

      setShowForm(false);
      resetForm();
      setEditingOrder(null);
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  const handleDelete = async (order) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this order?');
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, 'orders', order.id));

        const financeQuerySnapshot = await getDocs(query(collection(db, 'finance'), where('orderId', '==', order.id)));
        if (!financeQuerySnapshot.empty) {
          const financeDoc = financeQuerySnapshot.docs[0];
          await deleteDoc(doc(db, 'finance', financeDoc.id));
        }
      } catch (error) {
        console.error('Error deleting document: ', error);
      }
    }
  };

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const resetForm = () => {
    setJobType('');
    setQuantity('');
    setDeadline('');
    setMaterials('');
    setAmount('');
    setProductDetails('');
    setGstDetails('');
  };

  const handleEditClick = (order) => {
    setJobType(order.jobType);
    setQuantity(order.quantity);
    setDeadline(order.deadline);
    setMaterials(order.materials);
    setAmount(order.amount);
    setProductDetails(order.productDetails);
    setGstDetails(order.gstDetails);
    setEditingOrder(order);
    setShowForm(true);
  };

  return (
    <div className="orders-container">
      {showForm ? (
        <div>
          <h2>{editingOrder ? 'Edit Order' : 'Submit a New Order'}</h2>
          <form className="orders-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Product Name</label>
              <Autocomplete
                options={products}
                getOptionLabel={(option) => option.name}
                onChange={handleProductChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    value={jobType}
                    onChange={(e) => setJobType(e.target.value)}
                    placeholder="Enter Product Name"
                    required
                  />
                )}
              />
            </div>
            <div className="form-group">
              <label>Quantity</label>
              <input
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                placeholder="Enter Quantity"
                required
              />
            </div>
            <div className="form-group">
              <label>Deadline</label>
              <input
                type="date"
                value={deadline}
                onChange={(e) => setDeadline(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Materials</label>
              <input
                type="text"
                value={materials}
                onChange={(e) => setMaterials(e.target.value)}
                placeholder="Enter Materials"
                required
              />
            </div>
            <div className="form-group">
              <label>Amount</label>
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter Amount"
                required
              />
            </div>
            <div className="form-group">
              <label>Product Details</label>
              <input
                type="text"
                value={productDetails}
                onChange={(e) => setProductDetails(e.target.value)}
                placeholder="Enter Product Details"
                required
              />
            </div>
            <div className="form-group">
              <label>GST Details</label>
              <input
                type="text"
                value={gstDetails}
                onChange={(e) => setGstDetails(e.target.value)}
                placeholder="Enter GST Details"
                required
              />
            </div>
            <div className="buttons-form">
              <button type="submit" className="submit-button">
                {editingOrder ? 'Update Order' : 'Submit Order'}
              </button>
              <button type="button" className="toggle-dashboard-button" onClick={toggleForm}>
                Show Dashboard
              </button>
            </div>
          </form>
        </div>
      ) : (
        <OrderDashboard
          orders={orders}
          toggleForm={toggleForm}
          handleEdit={handleEditClick}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default Orders;

import { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { signOut } from 'firebase/auth';

const useCheckProductKey = () => {
  const [user] = useAuthState(auth);

  useEffect(() => {
    const checkProductKey = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const expirationDate = new Date(userData.productKeyExpirationDate);
          const currentDate = new Date();

          if (currentDate > expirationDate) {
            alert('Your product key has expired. Please renew to continue using the app.');
            await updateDoc(doc(db, 'users', user.uid), {
              productKeyValid: false,
            });
            await signOut(auth);
          }
        }
      }
    };

    checkProductKey();
  }, [user]);
};

export default useCheckProductKey;

import React from 'react';
import ProductList from '../../ProductList/ProductList';
import './RightPanel.css';

const RightPanel = () => (
  <div className='right-panel'>
    <ProductList />
  </div>
);

export default RightPanel;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = ({ user }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const renderProfileButton = () => {
    if (user && user.photoURL) {
      return (
        <Link to="/userprofile" className="profile-link" onClick={handleLinkClick}>
          <img src={user.photoURL} alt="Profile" className="profile-image" />
        </Link>
      );
    } else if (user && user.email) {
      const initial = user.email.charAt(0).toUpperCase();
      return (
        <Link to="/userprofile" className="profile-link" onClick={handleLinkClick}>
          <div className="default-profile">{initial}</div>
        </Link>
      );
    } else {
      return (
        <Link to="/userprofile" className="profile-link" onClick={handleLinkClick}>
          <div className="default-profile">U</div>
        </Link>
      );
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  return (
    <header className="app-header">
      <div className="prof-cont">
        <div className="hamburger" onClick={toggleMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <div className="logo-container">
          <Link to="/" className="logo-link" onClick={handleLinkClick}>
            <h1 className="logo-text">Offset Printing Press</h1>
          </Link>
        </div>
        <nav className={`nav-links ${menuOpen ? 'open active' : ''}`}>
          <ul className="nav-list">
            <li><Link to="/" onClick={handleLinkClick}>Home</Link></li>
            <li><Link to="/orders" onClick={handleLinkClick}>Orders</Link></li>
            <li><Link to="/tasks" onClick={handleLinkClick}>Tasks</Link></li>
            <li><Link to="/finance" onClick={handleLinkClick}>Finance</Link></li>
          </ul>
        </nav>
        <div className="profile-button">
          {renderProfileButton()}
        </div>
      </div>
    </header>
  );
};

export default Header;

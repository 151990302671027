import React, { useState, useEffect } from 'react';
import { collection, doc, setDoc, getDocs, deleteDoc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Button, Container, Grid, Paper, TextField, Typography } from '@mui/material';

const ProductKeyManager = () => {
  const [user] = useAuthState(auth);
  const [productKeys, setProductKeys] = useState([]);
  const [newKey, setNewKey] = useState('');
  const [newExpiration, setNewExpiration] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  const fetchProductKeys = async () => {
    const keysSnapshot = await getDocs(collection(db, 'productKeys'));
    const keysList = keysSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setProductKeys(keysList);
  };

  useEffect(() => {
    const checkAdmin = async () => {
      if (user) {
        const token = await user.getIdTokenResult();
        setIsAdmin(token.claims.admin || user.email === 'admin@gmail.com');
      }
    };

    if (user) {
      checkAdmin();
      fetchProductKeys();
    }
  }, [user]);

  const handleCreateKey = async () => {
    if (newKey && newExpiration) {
      await setDoc(doc(db, 'productKeys', newKey), {
        key: newKey,
        expirationDate: new Date(newExpiration).toISOString()
      });
      setNewKey('');
      setNewExpiration('');
      fetchProductKeys();
    }
  };

  const handleDeleteKey = async (id) => {
    await deleteDoc(doc(db, 'productKeys', id));
    setProductKeys(productKeys.filter(key => key.id !== id));
  };

  const handleUpdateExpiration = async (id, newExpirationDate) => {
    await updateDoc(doc(db, 'productKeys', id), {
      expirationDate: new Date(newExpirationDate).toISOString()
    });
    fetchProductKeys();
  };

  if (!user) {
    return <p>Please sign in</p>;
  }

  if (!isAdmin) {
    return <p>You do not have permission to view this page</p>;
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h4" gutterBottom>Product Key Manager</Typography>
        <div style={{ marginBottom: '20px' }}>
          <TextField
            label="New Product Key"
            value={newKey}
            onChange={(e) => setNewKey(e.target.value)}
            fullWidth
            style={{ marginBottom: '10px' }}
          />
          <TextField
            type="datetime-local"
            label="Expiration Date and Time"
            value={newExpiration}
            onChange={(e) => setNewExpiration(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <Button variant="contained" color="primary" onClick={handleCreateKey} style={{ marginTop: '10px' }}>Create Product Key</Button>
        </div>
        <Typography variant="h5" gutterBottom>Existing Product Keys</Typography>
        <Grid container spacing={2}>
          {productKeys.map((key) => (
            <Grid item xs={12} key={key.id}>
              <Paper elevation={1} style={{ padding: '10px' }}>
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <Typography>{key.id}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>{new Date(key.expirationDate).toLocaleString()}</Typography>
                  </Grid>
                  <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <TextField
                      type="datetime-local"
                      onChange={(e) => handleUpdateExpiration(key.id, e.target.value)}
                      InputLabelProps={{ shrink: true }}
                      style={{ marginRight: '10px' }}
                    />
                    <Button variant="outlined" color="primary" onClick={() => handleUpdateExpiration(key.id, key.expirationDate)}>Update</Button>
                    <Button variant="outlined" color="secondary" onClick={() => handleDeleteKey(key.id)} style={{ marginLeft: '10px' }}>Delete</Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Container>
  );
};

export default ProductKeyManager;

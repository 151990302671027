import React, { useState, useEffect } from 'react';
import { db, auth } from '../../firebase'; 
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Line, Pie } from 'react-chartjs-2';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, Typography, Card, CardContent, Box, Grid, FormControl, InputLabel } from '@mui/material';
import 'chart.js/auto';

const Finance = () => {
  const [orderDetails, setOrderDetails] = useState([]);
  const [xAxisOption, setXAxisOption] = useState('jobType');
  const [yAxisOption, setYAxisOption] = useState('amount');
  const [user] = useAuthState(auth);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      if (user) {
        const q = query(collection(db, 'orders'), where('userId', '==', user.uid));
        const ordersSnapshot = await getDocs(q);
        const ordersData = ordersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setOrderDetails(ordersData);
      }
    };

    fetchOrderDetails();
  }, [user]);

  const getTotalAmount = () => {
    return orderDetails.reduce((total, detail) => total + parseFloat(detail.amount || 0), 0);
  };

  const getTotalGST = () => {
    return orderDetails.reduce((total, detail) => total + parseFloat(detail.gstAmount || 0), 0);
  };

  const lineChartData = {
    labels: orderDetails.map(detail => detail[xAxisOption] || 'N/A'),
    datasets: [
      {
        label: 'Amount',
        data: orderDetails.map(detail => parseFloat(detail[yAxisOption] || 0)),
        fill: false,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
      },
    ],
  };

  const pieChartData = {
    labels: orderDetails.map(detail => detail.jobType || 'N/A'),
    datasets: [
      {
        label: 'Amount',
        data: orderDetails.map(detail => parseFloat(detail.amount || 0)),
        backgroundColor: orderDetails.map((_, index) => `hsl(${index * 36}, 70%, 50%)`),
      },
    ],
  };

  const pieChartOptions = {
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1.5,
  };

  const pieChartStyle = {
    width: '100%',
    height: '400px',
  };

  return (
    <Box sx={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
      <Typography variant="h4" gutterBottom>Finance Dashboard</Typography>
      <Grid container spacing={3} mb={4}>
        <Grid item xs={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Amount</Typography>
              <Typography variant="h5">₹{getTotalAmount().toFixed(2)}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total GST</Typography>
              <Typography variant="h5">₹{getTotalGST().toFixed(2)}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>Line Graph</Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>X-Axis</InputLabel>
            <Select value={xAxisOption} onChange={e => setXAxisOption(e.target.value)}>
              <MenuItem value="jobType">Product Name</MenuItem>
              <MenuItem value="id">Order ID</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Y-Axis</InputLabel>
            <Select value={yAxisOption} onChange={e => setYAxisOption(e.target.value)}>
              <MenuItem value="amount">Amount</MenuItem>
              <MenuItem value="gstDetails">GST</MenuItem>
            </Select>
          </FormControl>
          <Line data={lineChartData} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom>Pie Chart</Typography>
          <Box sx={pieChartStyle}>
            <Pie data={pieChartData} options={pieChartOptions} />
          </Box>
        </Grid>
      </Grid>

      <Typography variant="h5" gutterBottom style={{ marginTop: '20px' }}>Detailed Finance Information</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order Name</TableCell>
              <TableCell>Amount (₹)</TableCell>
              <TableCell>GST (₹)</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderDetails.map(detail => (
              <TableRow key={detail.id}>
                <TableCell>{detail.jobType || 'N/A'}</TableCell>
                <TableCell>{parseFloat(detail.amount || 0).toFixed(2)}</TableCell>
                <TableCell>{parseFloat(detail.gstDetails || 0).toFixed(2)}</TableCell>
                <TableCell>{detail.status || 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Finance;

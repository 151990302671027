import React from "react";
import './Footer.css';


const Footer = () => {
    return(
        <div>
<div className="app-footer">
<p>&copy; 2024 Offset Printing Press. All rights reserved.</p>
<div className="footer-social">
  <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
  <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
  <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a>
</div>
</div>
</div>
    );
}

export default Footer;
import React, { useState, useEffect } from 'react';
import { db } from '../../firebase'; // Adjust the path as needed
import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs } from 'firebase/firestore';
import './ProductList.css';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [form, setForm] = useState({ name: '', details: '', gst: '' });

  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productsList);
    };

    fetchProducts();
  }, []);

  const handleOpen = (product) => {
    setCurrentProduct(product);
    setForm(product ? { name: product.name, details: product.details, gst: product.gst } : { name: '', details: '', gst: '' });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentProduct(null);
    setForm({ name: '', details: '', gst: '' });
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (currentProduct) {
      await updateDoc(doc(db, 'products', currentProduct.id), form);
    } else {
      await addDoc(collection(db, 'products'), form);
    }
    handleClose();
    window.location.reload(); // For simplicity, reload to fetch the updated list
  };

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, 'products', id));
    window.location.reload(); // For simplicity, reload to fetch the updated list
  };

  return (
    <div className="product-list-container">
      <h6>Product List</h6>
      <button className="add-button" onClick={() => handleOpen(null)}>Add Product</button>
      <ul className="product-list">
        {products.map(product => (
          <li key={product.id}>
            <span>{product.name}</span>
            <span>Details: {product.details}, GST: {product.gst}</span>
            <div className="actions">
              <button className="edit-button" onClick={() => handleOpen(product)}>Edit</button>
              <button className="delete-button" onClick={() => handleDelete(product.id)}>Delete</button>
            </div>
          </li>
        ))}
      </ul>
      {open && (
        <div className="modal">
          <div className="modal-content">
            <h2>{currentProduct ? 'Edit Product' : 'Add Product'}</h2>
            <input
              type="text"
              name="name"
              placeholder="Product Name"
              value={form.name}
              onChange={handleChange}
            />
            <input
              type="text"
              name="details"
              placeholder="Product Details"
              value={form.details}
              onChange={handleChange}
            />
            <input
              type="text"
              name="gst"
              placeholder="GST"
              value={form.gst}
              onChange={handleChange}
            />
            <div className="modal-actions">
              <button onClick={handleClose}>Cancel</button>
              <button onClick={handleSubmit}>{currentProduct ? 'Update' : 'Add'}</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductList;

import React, { useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, Typography, useMediaQuery, Dialog, DialogTitle, DialogContent,
  DialogContentText, DialogActions
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import SearchBar from '../SearchBar/SearchBar';
import './orderDashboard.component.css';

const OrderDashboard = ({ orders, toggleForm, handleEdit, handleDelete }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [viewOrder, setViewOrder] = useState(null);
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const filteredOrders = orders.filter(order =>
    order.jobType?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    order.quantity?.toString().includes(searchQuery) ||
    order.deadline?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    order.materials?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    order.completionPercentage?.toString().includes(searchQuery) ||
    order.productDetails?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    order.gstDetails?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleView = (order) => {
    setViewOrder(order);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Typography variant="h4" component="h2" gutterBottom>
        Order Dashboard
      </Typography>
      <Button variant="contained" color="primary" onClick={toggleForm} style={{ marginBottom: '20px' }}>
        Add Order
      </Button>
      <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      <TableContainer component={Paper}>
        <Table className="orders-table">
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Deadline</TableCell>
              {!isMobile && <TableCell>Materials</TableCell>}
              <TableCell>Status</TableCell>
              {!isMobile && <TableCell>Product Details</TableCell>}
              {!isMobile && <TableCell>GST Details</TableCell>}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredOrders.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No products added.
                </TableCell>
              </TableRow>
            ) : (
              filteredOrders.map(order => (
                <React.Fragment key={order.id}>
                  <TableRow>
                    <TableCell>{order.jobType}</TableCell>
                    <TableCell>{order.quantity}</TableCell>
                    <TableCell>{order.deadline}</TableCell>
                    {!isMobile && <TableCell>{order.materials}</TableCell>}
                    <TableCell>
                      <div className="progress-bar">
                        <div className="progress" style={{ width: `${order.completionPercentage}%` }}>
                          {order.completionPercentage}
                        </div>
                      </div>
                    </TableCell>
                    {!isMobile && <TableCell>{order.productDetails}</TableCell>}
                    {!isMobile && <TableCell>{order.gstDetails}</TableCell>}
                    <TableCell>
                      <Button onClick={() => handleEdit(order)} startIcon={<Edit />} color="primary">
                        Edit
                      </Button>
                      <Button onClick={() => handleDelete(order)} startIcon={<Delete />} color="secondary">
                        Delete
                      </Button>
                      {isMobile && (
                        <Button onClick={() => handleView(order)} color="primary">
                          View
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Order Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {viewOrder && (
              <>
                <Typography variant="body1"><strong>Product Name:</strong> {viewOrder.jobType}</Typography>
                <Typography variant="body1"><strong>Quantity:</strong> {viewOrder.quantity}</Typography>
                <Typography variant="body1"><strong>Deadline:</strong> {viewOrder.deadline}</Typography>
                <Typography variant="body1"><strong>Materials:</strong> {viewOrder.materials}</Typography>
                <Typography variant="body1"><strong>Product Details:</strong> {viewOrder.productDetails}</Typography>
                <Typography variant="body1"><strong>GST Details:</strong> {viewOrder.gstDetails}</Typography>
                <Typography variant="body1"><strong>Completion Percentage:</strong> {viewOrder.completionPercentage}%</Typography>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderDashboard;

import React from 'react';
import { TextField } from '@mui/material';

const SearchBar = ({ searchQuery, setSearchQuery }) => {
  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <TextField
      label="Search Orders"
      variant="outlined"
      fullWidth
      value={searchQuery}
      onChange={handleChange}
      style={{ marginBottom: '20px' }}
    />
  );
};

export default SearchBar;

import React, { useState } from 'react';
import Slider from 'react-slick';
import { Paper, Typography, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import './AdCarousel.css'; // Custom CSS for styling
import Img1 from '../../../assests/Neon Acrylic Custom Logo Decoration, 3D LED Logo, Custom Shop Signage, Neon Signs For Office Decor, Beauty Bar Sign.jpg';
import Img2 from '../../../assests/Great Logo Design.jpg';
import Img3 from '../../../assests/Party Ghost Logo.jpg';
import Img4 from '../../../assests/Premium Vector _ Fresh food typography logo design premium vector.jpg';
import Img5 from '../../../assests/ab44ddf2-b06a-4623-acf3-14f64158dd14.jpg';
import Img6 from '../../../assests/The 7 Types of Logos And How to Use Them _ VistaPrint US.jpg';



const AdCarousel = () => {
  const [showAds, setShowAds] = useState(true);

  const handleHideAds = () => {
    setShowAds(false);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Show 5 ads at a time on desktop
    slidesToScroll: 1,
    centerMode: true, // Center the middle slide
    centerPadding: '0', // Remove extra padding around the center slide
    focusOnSelect: true,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Time in milliseconds between slides
    cssEase: 'ease-in-out', // Smooth transition effect
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // Show 3 ads at a time on tablet
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Show 1 ad at a time on mobile
          centerMode: false, // Disable center mode on mobile
        }
      }
    ]
  };

  if (!showAds) {
    return null; // Don't render anything if ads are hidden
  }

  return (
    <div className="ad-carousel-container">
      {/* <Paper elevation={2} className="ad-carousel-paper"> */}
        <div className="ad-carousel-header">
          {/* <Typography variant="h6">Advertisements</Typography> */}
          <IconButton onClick={handleHideAds} className="hide-ads-button">
            <Close />
          </IconButton>
        </div>
        <Slider {...settings}>
          <div className="ad-carousel-slide">
            <img src={Img6} alt="Ad 1" className="ad-carousel-image" />
          </div>
          <div className="ad-carousel-slide">
            <img src={Img6} alt="Ad 2" className="ad-carousel-image" />
          </div>
          <div className="ad-carousel-slide">
            <img src={Img6} alt="Ad 3" className="ad-carousel-image" />
          </div>
          <div className="ad-carousel-slide">
            <img src={Img6} alt="Ad 4" className="ad-carousel-image" />
          </div>
          <div className="ad-carousel-slide">
            <img src={Img6} alt="Ad 5" className="ad-carousel-image" />
          </div>
          <div className="ad-carousel-slide">
            <img src={Img6} alt="Ad 6" className="ad-carousel-image" />
          </div>
        </Slider>
      {/* </Paper> */}
    </div>
  );
};

export default AdCarousel;

import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../../firebase';
import { collection, getDocs, onSnapshot, updateDoc, doc, writeBatch, query, where } from 'firebase/firestore';
import { Button, Typography, TextField, Checkbox, List, ListItem, ListItemText, Paper, Modal, Backdrop, Fade, Tabs, Tab } from '@mui/material';
import './Tasks.css';

const Tasks = () => {
  const [tasks, setTasks] = useState([]);
  const [orders, setOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [customTasks, setCustomTasks] = useState([]);
  const [taskDescription, setTaskDescription] = useState('');
  const [editingTask, setEditingTask] = useState(null);
  const [editingTaskDescription, setEditingTaskDescription] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [modalTasks, setModalTasks] = useState([]);
  const [view, setView] = useState('active');

  const ordersPerPage = 10;
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    const fetchTasks = async () => {
      const tasksQuery = query(collection(db, 'tasks'), where('userId', '==', user.uid));
      const snapshot = await getDocs(tasksQuery);
      const tasksData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTasks(tasksData);
    };
    fetchTasks();
  }, [user, navigate]);

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    const ordersQuery = query(collection(db, 'orders'), where('userId', '==', user.uid));
    const unsubscribe = onSnapshot(ordersQuery, (snapshot) => {
      const ordersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      const activeOrders = ordersData.filter(order => !order.completed);
      const completedOrders = ordersData.filter(order => order.completed);
      setOrders(activeOrders);
      setCompletedOrders(completedOrders);
    });

    return () => unsubscribe();
  }, [user, navigate]);

  const handleAddTask = (order) => {
    setSelectedOrder(order);
    setCustomTasks([]);
    setTaskDescription('');
  };

  const handleTaskInputChange = (e) => {
    setTaskDescription(e.target.value);
  };

  const handleAddCustomTask = () => {
    if (taskDescription.trim() !== '') {
      setCustomTasks(prevTasks => [...prevTasks, { description: taskDescription, status: 'pending' }]);
      setTaskDescription('');
    }
  };

  const handleConfirmAddTask = async () => {
    if (!selectedOrder) return;

    const { id, jobType, quantity, deadline, materials, amount } = selectedOrder;

    const batch = writeBatch(db);

    customTasks.forEach(task => {
      const newTaskRef = doc(collection(db, 'tasks'));
      batch.set(newTaskRef, {
        description: task.description,
        status: 'pending',
        orderId: id,
        orderDetails: { jobType, quantity, deadline, materials, amount },
        userId: user.uid,
      });
    });

    try {
      await batch.commit();

      const updatedOrders = orders.map(order => {
        if (order.id === id) {
          return {
            ...order,
            completionPercentage: '0%'
          };
        }
        return order;
      });

      setOrders(updatedOrders);
      setSelectedOrder(null);
      setCustomTasks([]);

      const tasksQuery = query(collection(db, 'tasks'), where('userId', '==', user.uid));
      const snapshot = await getDocs(tasksQuery);
      const tasksData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTasks(tasksData);

    } catch (error) {
      console.error('Error committing batch:', error);
    }
  };

  const handleTaskCheckboxChange = async (task, order) => {
    const taskRef = doc(db, 'tasks', task.id);
    const newStatus = task.status === 'completed' ? 'pending' : 'completed';

    await updateDoc(taskRef, { status: newStatus });

    const taskQuerySnapshot = await getDocs(query(collection(db, 'tasks'), where('orderId', '==', order.id)));
    const totalTasks = taskQuerySnapshot.docs.length;
    const completedTasks = taskQuerySnapshot.docs.filter(doc => doc.data().status === 'completed').length;

    const completionPercentage = Math.round((completedTasks / totalTasks) * 100) + '%';

    await updateDoc(doc(db, 'orders', order.id), { completionPercentage });

    setTasks(prevTasks =>
      prevTasks.map(t => (t.id === task.id ? { ...t, status: newStatus } : t))
    );

    const updatedOrders = orders.map(o =>
      o.id === order.id ? { ...o, completionPercentage } : o
    );

    setOrders(updatedOrders);
  };

  const handleEditTask = (task) => {
    setEditingTask(task);
    setEditingTaskDescription(task.description);
  };

  const handleEditTaskInputChange = (e) => {
    setEditingTaskDescription(e.target.value);
  };

  const handleConfirmEditTask = async () => {
    const taskRef = doc(db, 'tasks', editingTask.id);
    await updateDoc(taskRef, { description: editingTaskDescription });

    setTasks(prevTasks =>
      prevTasks.map(t => (t.id === editingTask.id ? { ...t, description: editingTaskDescription } : t))
    );

    setEditingTask(null);
    setEditingTaskDescription('');
  };

  const handleFinishOrder = async (orderId) => {
    const orderRef = doc(db, 'orders', orderId);
    await updateDoc(orderRef, { completed: true });

    const updatedOrders = orders.filter(order => order.id !== orderId);
    const completedOrder = orders.find(order => order.id === orderId);

    setOrders(updatedOrders);
    setCompletedOrders(prevCompletedOrders => [
      ...prevCompletedOrders,
      completedOrder
    ]);

    const tasksQuery = query(collection(db, 'tasks'), where('userId', '==', user.uid));
    const snapshot = await getDocs(tasksQuery);
    const tasksData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setTasks(tasksData);
  };

  const handleDeleteOrder = async (orderId) => {
    const orderRef = doc(db, 'orders', orderId);
    const taskQuerySnapshot = await getDocs(query(collection(db, 'tasks'), where('orderId', '==', orderId)));

    const batch = writeBatch(db);

    taskQuerySnapshot.docs.forEach(taskDoc => {
      batch.delete(doc(db, 'tasks', taskDoc.id));
    });

    batch.delete(orderRef);

    try {
      await batch.commit();

      setOrders(prevOrders => prevOrders.filter(order => order.id !== orderId));
      setTasks(prevTasks => prevTasks.filter(task => task.orderId !== orderId));
      setCompletedOrders(prevCompletedOrders => prevCompletedOrders.filter(order => order.id !== orderId));
    } catch (error) {
      console.error('Error deleting order and tasks:', error);
    }
  };

  const handleViewTasks = (orderId) => {
    const orderTasks = tasks.filter(task => task.orderId === orderId);
    setModalTasks(orderTasks);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);

  return (
    <div className="tasks-container">
      <Typography variant="h4" component="h3" gutterBottom>
        Orders
      </Typography>

      <Tabs value={view} onChange={(e, newValue) => setView(newValue)} centered>
        <Tab label="Active Orders" value="active" />
        <Tab label="Completed Orders" value="completed" />
      </Tabs>

      {view === 'active' && (
        <>
          {currentOrders.map(order => {
            const orderTasks = tasks.filter(task => task.orderId === order.id);
            const pendingTasks = orderTasks.filter(task => task.status !== 'completed').length;

            return (
              <Paper key={order.id} className="order-container">
                <Typography variant="h5" component="h4" gutterBottom>
                  {order.jobType}
                </Typography>
                <Typography variant="body1">
                  Quantity: {order.quantity}
                </Typography>
                <Typography variant="body1">
                  Deadline: {order.deadline}
                </Typography>
                <Typography variant="body1">
                  Materials: {order.materials}
                </Typography>

                <div className="order-buttons">
                  <Button
                    variant="contained"
                    color="primary"
                    className="add-task-button"
                    onClick={() => handleAddTask(order)}
                  >
                    Add Task
                  </Button>

                  <Button
                    variant="contained"
                    color="secondary"
                    className="delete-order-button"
                    onClick={() => handleDeleteOrder(order.id)}
                  >
                    Delete Order
                  </Button>
                </div>

                {selectedOrder && selectedOrder.id === order.id && (
                  <div className="custom-task-container">
                    <TextField
                      value={taskDescription}
                      onChange={handleTaskInputChange}
                      fullWidth
                      placeholder="Task description"
                      variant="outlined"
                      style={{ marginTop: '10px' }}
                    />
                    <div className="task-action-buttons">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddCustomTask}
                        style={{ marginTop: '10px' }}
                      >
                        Add Custom Task
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleConfirmAddTask}
                        style={{ marginTop: '10px', marginLeft: '10px' }}
                      >
                        Confirm
                      </Button>
                    </div>
                  </div>
                )}

                {orderTasks.map(task => (
                  <List key={task.id} className="task-list">
                    <ListItem>
                      <Checkbox
                        checked={task.status === 'completed'}
                        onChange={() => handleTaskCheckboxChange(task, order)}
                        color="primary"
                      />
                      {editingTask && editingTask.id === task.id ? (
                        <TextField
                          value={editingTaskDescription}
                          onChange={handleEditTaskInputChange}
                          fullWidth
                          variant="outlined"
                          style={{ marginLeft: '10px' }}
                        />
                      ) : (
                        <ListItemText primary={task.description} />
                      )}
                      <span className={`task-status ${task.status}`}>
                        {task.status.charAt(0).toUpperCase() + task.status.slice(1)}
                      </span>
                      {editingTask && editingTask.id === task.id ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleConfirmEditTask}
                          style={{ marginLeft: '10px' }}
                        >
                          Save
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          className="edit-button"
                          onClick={() => handleEditTask(task)}
                        >
                          Edit
                        </Button>
                      )}
                    </ListItem>
                  </List>
                ))}

                {pendingTasks === 0 && orderTasks.length > 0 && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleFinishOrder(order.id)}
                    style={{ marginTop: '10px' }}
                  >
                    Finish Order
                  </Button>
                )}
              </Paper>
            );
          })}

          <div className="pagination">
            {Array.from({ length: Math.ceil(orders.length / ordersPerPage) }, (_, index) => (
              <Button
                key={index}
                variant="contained"
                color={currentPage === index + 1 ? 'primary' : 'default'}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Button>
            ))}
          </div>
        </>
      )}

      {view === 'completed' && (
        <>
          {completedOrders.map(order => (
            <Paper key={order.id} className="order-container">
              <Typography variant="h5" component="h4" gutterBottom>
                {order.jobType}
              </Typography>
              <Typography variant="body1">
                Quantity: {order.quantity}
              </Typography>
              <Typography variant="body1">
                Deadline: {order.deadline}
              </Typography>
              <Typography variant="body1">
                Materials: {order.materials}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleViewTasks(order.id)}
                style={{ marginTop: '10px'}}
              >
                View Tasks
              </Button>
            </Paper>
          ))}
        </>
      )}

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className="modal-content">
            <Typography variant="h6" gutterBottom>
              Tasks
            </Typography>
            <List className="modal-task-list">
              {modalTasks.map(task => (
                <ListItem key={task.id} className="modal-task-item">
                  <ListItemText primary={task.description} />
                  <span className={`modal-task-status ${task.status}`}>
                    {task.status.charAt(0).toUpperCase() + task.status.slice(1)}
                  </span>
                </ListItem>
              ))}
            </List>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCloseModal}
              style={{ marginTop: '10px' }}
            >
              Close
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default Tasks;
